/* ==========================================================================
Hide ng-cloak on page load, https://docs.angularjs.org/api/ng/directive/ngCloak
========================================================================== */
[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
  display: none !important; }

/* ==========================================================================
Developement Ribbon
========================================================================== */
.ribbon {
  background-color: #a00;
  box-shadow: 0 0 10px #888;
  left: -3.5em;
  moz-box-shadow: 0 0 10px #888;
  moz-transform: rotate(-45deg);
  ms-transform: rotate(-45deg);
  o-transform: rotate(-45deg);
  overflow: hidden;
  position: absolute;
  top: 40px;
  transform: rotate(-45deg);
  webkit-box-shadow: 0 0 10px #888;
  webkit-transform: rotate(-45deg);
  white-space: nowrap;
  width: 15em;
  z-index: 9999;
  pointer-events: none; }
  .ribbon a {
    border: 1px solid #faa;
    color: #fff;
    display: block;
    font: bold 81.25% 'Helvetica Neue', Helvetica, Arial, sans-serif;
    margin: 1px 0;
    padding: 10px 50px;
    text-align: center;
    text-decoration: none;
    text-shadow: 0 0 5px #444;
    pointer-events: none; }

div:focus {
  outline: none; }

.navbar {
  background-color: #E1F5FE; }

.navbar-full-width {
  min-width: 1400px; }

/* ==========================================================================
Version number in navbar
========================================================================== */
.navbar-version {
  font-size: 10px;
  color: #ccc; }

/* ==========================================================================
Browser Upgrade Prompt
========================================================================== */
.browserupgrade {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0; }

/* ==========================================================================
Logo styles
========================================================================== */
.navbar-brand.logo {
  padding: 5px 15px; }
  .navbar-brand.logo .logo-img {
    display: inline-block;
    height: 20px;
    margin-top: 10px; }

/* ==========================================================================
Main page styles
========================================================================== */
.hero-unit {
  background-color: #eee;
  border-radius: 6px;
  margin: 50px auto 0 auto;
  width: 300px;
  font-size: 18px;
  font-weight: 200;
  line-height: 30px;
  padding: 60px; }
  .hero-unit h1 {
    font-size: 60px;
    line-height: 1;
    letter-spacing: -1px; }

.mtv {
  display: inline-block;
  width: 347px;
  height: 497px;
  background: url("../images/log-light.png") no-repeat center top;
  background-size: contain; }

/* wait autoprefixer update to allow simple generation of high pixel density media query */
@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2 / 1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  .mtv {
    background: url("../images/logo-light.png") no-repeat center top;
    background-size: contain; } }

/* ==========================================================================
Generic styles
========================================================================== */
.error {
  color: white;
  background-color: red; }

.pad {
  padding: 10px; }

.break {
  white-space: normal;
  word-break: break-all; }

.voffset {
  margin-top: 2px; }

.voffset1 {
  margin-top: 5px; }

.voffset2 {
  margin-top: 10px; }

.voffset3 {
  margin-top: 15px; }

.voffset4 {
  margin-top: 30px; }

.voffset5 {
  margin-top: 40px; }

.voffset6 {
  margin-top: 60px; }

.voffset7 {
  margin-top: 80px; }

.voffset8 {
  margin-top: 100px; }

.voffset9 {
  margin-top: 150px; }

.readonly {
  background-color: #eee;
  opacity: 1; }

/* ==========================================================================
make sure browsers use the pointer cursor for anchors, even with no href
========================================================================== */
a:hover {
  cursor: pointer; }

.hand {
  cursor: pointer; }

/* ==========================================================================
Metrics and Health styles
========================================================================== */
#threadDump .popover, #healthCheck .popover {
  top: inherit;
  display: block;
  font-size: 10px;
  max-width: 1024px; }

#healthCheck .popover {
  margin-left: -50px; }

.health-details {
  min-width: 400px; }

/* ==========================================================================
start Password strength bar style
========================================================================== */
ul#strength {
  display: inline;
  list-style: none;
  margin: 0;
  margin-left: 15px;
  padding: 0;
  vertical-align: 2px; }

.point {
  background: #DDD;
  border-radius: 2px;
  display: inline-block;
  height: 5px;
  margin-right: 1px;
  width: 20px; }
  .point:last {
    margin: 0 !important; }

/* ==========================================================================
Custom alerts for notification
========================================================================== */
.alerts-wrapper {
  padding: 10px;
  position: absolute;
  z-index: 100; }

.alerts .alert {
  text-overflow: ellipsis;
  width: 320px; }
  .alerts .alert pre {
    background: none;
    border: none;
    font: inherit;
    color: inherit;
    padding: 0;
    margin: 0; }
  .alerts .alert .popover pre {
    font-size: 10px; }

.alerts .toast {
  position: fixed;
  width: 100%; }
  .alerts .toast.left {
    left: 5px; }
  .alerts .toast.right {
    right: 5px; }
  .alerts .toast.top {
    top: 55px; }
  .alerts .toast.bottom {
    bottom: 55px; }

@media screen and (min-width: 480px) {
  .alerts .toast {
    width: 50%; } }

/* ==========================================================================
entity tables helpers
========================================================================== */
/* Remove Bootstrap padding from the element
   http://stackoverflow.com/questions/19562903/remove-padding-from-columns-in-bootstrap-3 */
.no-padding-left {
  padding-left: 0 !important; }

.no-padding-right {
  padding-right: 0 !important; }

.no-padding-top {
  padding-top: 0 !important; }

.no-padding-bottom {
  padding-bottom: 0 !important; }

.no-padding {
  padding: 0 !important; }

/* bootstrap 3 input-group 100% width
   http://stackoverflow.com/questions/23436430/bootstrap-3-input-group-100-width */
.width-min {
  width: 1% !important; }

/* Makes toolbar not wrap on smaller screens
   http://www.sketchingwithcss.com/samplechapter/cheatsheet.html#right */
.flex-btn-group-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-justify-content: flex-end;
  justify-content: flex-end; }

.jh-table > tbody > tr > td {
  /* Align text in td verifically (top aligned by Bootstrap) */
  vertical-align: middle; }

.jh-table > thead > tr > th > .glyphicon-sort, .jh-table > thead > tr > th > .glyphicon-sort-by-attributes, .jh-table > thead > tr > th > .glyphicon-sort-by-attributes-alt {
  /* less visible sorting icons */
  opacity: 0.5; }
  .jh-table > thead > tr > th > .glyphicon-sort:hover, .jh-table > thead > tr > th > .glyphicon-sort-by-attributes:hover, .jh-table > thead > tr > th > .glyphicon-sort-by-attributes-alt:hover {
    /* full visible sorting icons and pointer when mouse is over them */
    opacity: 1;
    cursor: pointer; }

/* ==========================================================================
entity detail page css
========================================================================== */
.dl-horizontal.jh-entity-details > dd {
  margin-bottom: 15px; }

@media screen and (min-width: 768px) {
  .dl-horizontal.jh-entity-details > dt {
    margin-bottom: 15px; }
  .dl-horizontal.jh-entity-details > dd {
    border-bottom: 1px solid #eee;
    padding-left: 180px;
    margin-left: 0; } }

/* ==========================================================================
ui bootstrap tweaks
========================================================================== */
.nav, .pagination, .carousel, .panel-title a {
  cursor: pointer; }

.datetime-picker-dropdown > li.date-picker-menu div > table .btn-default,
.uib-datepicker-popup > li > div.uib-datepicker > table .btn-default {
  border: 0; }

.datetime-picker-dropdown > li.date-picker-menu div > table:focus,
.uib-datepicker-popup > li > div.uib-datepicker > table:focus {
  outline: none; }

@charset "UTF-8";
.paging {
  margin: 0;
  padding: 0;
  list-style: none; }

.clearfix:after, #footer:after, .form-group:after, .input-control:after, .filter-list li:after {
  content: '';
  display: block;
  clear: both; }

.ellipsis {
  white-space: nowrap;
  /* 1 */
  text-overflow: ellipsis;
  /* 2 */
  overflow: hidden; }

#header {
  border: 0;
  color: #163857;
  padding: 13px 0 0;
  position: absolute;
  width: 100%;
  z-index: 7; }
  #header #profile-button {
    padding-right: 15px; }
  #header .logo {
    margin-right: 40px; }
  #header .title {
    font-size: 18px;
    font-weight: normal;
    padding-left: 20px; }

.anchor-nav {
  padding: 0 0 0 20px; }
  .anchor-nav ul {
    font-weight: 600;
    margin: 0; }
    .anchor-nav ul li {
      display: inline-block;
      padding-right: 60px;
      vertical-align: top; }
      @media (max-width: 1599px) {
        .anchor-nav ul li {
          padding-right: 25px; } }
      .anchor-nav ul li.active a {
        color: #1565c0; }
      .anchor-nav ul li a {
        color: #78909c; }
        .anchor-nav ul li a:hover {
          color: #1565c0; }
      .anchor-nav ul li.trade-id {
        color: #0e63b3;
        font-weight: 600;
        padding-left: 0;
        padding-right: 0; }

.read-mode .anchor-nav ul {
  font-size: 18px;
  font-weight: normal; }

#footer {
  background: #f7fbfc;
  bottom: 0;
  color: #8d99a4;
  font-size: 13px;
  line-height: 14px;
  height: 80px;
  padding: 10px 0 0;
  position: absolute;
  width: 100%; }
  #footer ul {
    margin-top: 5px; }
    #footer ul li {
      border-left: 1px solid #6e92b4;
      display: inline;
      padding: 0 4px; }
      #footer ul li:first-child {
        border: 0;
        padding-left: 0; }
  #footer .logo {
    margin-top: 7px; }

.login-page {
  background: url(../images/login-background.png) center top;
  background-size: 100% auto;
  height: 100%; }
  .login-page .footer {
    background: none !important; }
  .login-page #footer {
    height: 100px;
    color: black; }
  .login-page #footer ul {
    background: #d6f1fc;
    border: solid 1px lightblue;
    font-size: 20px;
    padding: 10px 0; }
  .login-page #footer ul li {
    border: none;
    padding: 0 30px; }
  .login-page .login-image {
    left: 0;
    position: absolute;
    right: 0; }
  .login-page .dpc-logo {
    top: 75%; }
  .login-page .mtv-logo {
    top: 5%; }
  .login-page .mtv-text {
    top: 15%; }

.login-form {
  background: #9ecde3;
  border-radius: 2px;
  color: #fff;
  font-size: 15px;
  left: 50%;
  margin-top: -30px;
  padding: 16px 40px 24px;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 450px; }
  .login-form .holder {
    padding: 0 18px; }
    .login-form .holder.bottom {
      padding: 11px 18px 11px; }
  .login-form .form-group {
    margin-bottom: 10px;
    padding-left: 8px;
    padding-right: 8px;
    position: relative; }
    .login-form .form-group .icon {
      position: absolute;
      right: 18px;
      top: 14px; }
      .login-form .form-group .icon:hover {
        cursor: pointer;
        opacity: 0.7; }
  .login-form .form-control {
    border: 0;
    border-radius: 2px;
    color: #000;
    font-size: 14px;
    height: 48px;
    padding: 6px 60px 6px 25px; }
    .login-form .form-control::-webkit-input-placeholder {
      color: #737373; }
    .login-form .form-control::-moz-placeholder {
      color: #737373; }
    .login-form .form-control:-ms-input-placeholder {
      color: #737373; }
    .login-form .form-control:-moz-placeholder {
      color: #737373; }
  .login-form .title {
    display: block;
    font-size: 24px;
    font-weight: normal;
    line-height: 30px;
    padding: 0 0 17px; }
  .login-form ul li:last-child {
    padding-left: 25px; }
    .login-form ul li:last-child a {
      border-bottom: 1px solid #bbdceb;
      color: #fff;
      display: inline-block;
      vertical-align: top; }
      .login-form ul li:last-child a:hover {
        border-bottom: 1px solid #fff; }
  .login-form .btn {
    display: block;
    font-size: 26px;
    line-height: 40px;
    width: 100%; }

.custom-checkbox {
  margin-bottom: 4px;
  min-height: 24px;
  min-width: 25px;
  position: relative;
  /* Base for label styling */
  /* checkbox aspect */
  /* checked mark aspect */
  /* checked mark aspect changes */
  /* disabled checkbox */ }
  .custom-checkbox label {
    line-height: 26px;
    margin-bottom: 0; }
  .custom-checkbox [type="checkbox"]:not(:checked),
  .custom-checkbox [type="checkbox"]:checked {
    left: -9999px;
    position: absolute; }
  .custom-checkbox [type="checkbox"]:not(:checked) + label,
  .custom-checkbox [type="checkbox"]:checked + label {
    cursor: pointer;
    padding-left: 25px;
    vertical-align: top; }
  .custom-checkbox [type="checkbox"]:not(:checked) + label:before,
  .custom-checkbox [type="checkbox"]:checked + label:before {
    background: #fff;
    border-radius: 3px;
    content: '';
    height: 16px;
    left: 0;
    position: absolute;
    top: 5px;
    width: 16px; }
  .custom-checkbox [type="checkbox"]:checked + label:before {
    background: #82add9; }
  .custom-checkbox [type="checkbox"]:not(:checked) + label:after,
  .custom-checkbox [type="checkbox"]:checked + label:after {
    color: #fff;
    content: '✔';
    font-size: 11px;
    left: 4px;
    line-height: 0.8;
    position: absolute;
    top: 9px;
    transition: all .2s; }
  .custom-checkbox [type="checkbox"]:not(:checked) + label:after {
    opacity: 0;
    transform: scale(0); }
  .custom-checkbox [type="checkbox"]:checked + label:after {
    opacity: 1;
    transform: scale(1); }
  .custom-checkbox [type="checkbox"]:disabled:not(:checked) + label:before,
  .custom-checkbox [type="checkbox"]:disabled:checked + label:before {
    background-color: #ddd;
    border-color: #bbb;
    box-shadow: none; }
  .custom-checkbox [type="checkbox"]:disabled:checked + label:after {
    color: #999; }
  .custom-checkbox [type="checkbox"]:disabled + label {
    color: #aaa; }

.input-buy-sell {
  background: #f6eec9;
  border-radius: 50px;
  height: 40px;
  margin-bottom: 14px;
  position: relative;
  width: 96px; }
  .input-buy-sell label {
    background: #ff8227;
    border-radius: 50px;
    cursor: pointer;
    display: block;
    height: 34px;
    left: 3px;
    position: absolute;
    top: 3px;
    width: 53px; }
    .input-buy-sell label.loaded {
      transition: all 0.4s ease; }
    .input-buy-sell label:after {
      color: #fff;
      content: "BUY";
      font-size: 14px;
      font-weight: bold;
      left: 0;
      line-height: 34px;
      position: absolute;
      text-align: center;
      top: 0;
      width: 100%; }
  .input-buy-sell input[type=checkbox] {
    visibility: hidden; }
    .input-buy-sell input[type=checkbox]:checked + label {
      background: #2bbd2b;
      left: 40px; }
      .input-buy-sell input[type=checkbox]:checked + label:after {
        content: "SELL"; }

.modal .custom-checkbox [type="checkbox"]:checked + label:before,
#content-main .custom-checkbox [type="checkbox"]:checked + label:before {
  background: #0e5fb0; }

.modal .custom-checkbox [type="checkbox"]:not(:checked) + label:before,
#content-main .custom-checkbox [type="checkbox"]:not(:checked) + label:before {
  border: 1px solid #cfddea; }

.btn {
  font-weight: 600;
  line-height: 26px;
  min-width: 25px; }
  .btn .icon {
    margin-right: 11px; }

.btn-clear {
  color: #e57373;
  font-weight: 600;
  line-height: 30px; }
  .btn-clear:hover {
    color: #e57373;
    opacity: 0.8; }
  .btn-clear .icon {
    margin-right: 8px; }

.btn-link .underline {
  border-bottom: 1px solid #b4dafb;
  display: inline-block;
  position: relative; }

.btn-link:hover {
  text-decoration: none; }
  .btn-link:hover .underline {
    border-color: #0864ba; }

.btn-primary {
  background: linear-gradient(to bottom, #2599f3 0%, #1f91f2 100%);
  border: 0;
  box-shadow: inset 0px 1px 0 #60c6f9, 0 2px 3px rgba(0, 0, 0, 0.5); }
  .btn-primary:active {
    border: 0;
    box-shadow: none; }
  .btn-primary:hover {
    opacity: 0.7; }

.btn-lg {
  font-size: 20px;
  font-weight: 600;
  line-height: 30px; }

.btn-approved {
  color: #558b2f;
  font-size: 20px; }

#sidebar {
  background: #163857;
  display: none;
  height: 100%;
  left: 0;
  padding: 20px;
  position: absolute;
  top: 0;
  width: 265px;
  z-index: 10; }
  #sidebar > .logo {
    margin-bottom: 38px; }
  #sidebar .footer {
    bottom: 7px;
    color: #8d99a4;
    font-size: 13px;
    left: 20px;
    line-height: 14px;
    position: absolute; }
    #sidebar .footer ul {
      margin: 0 0 11px; }
      #sidebar .footer ul li {
        border-left: 1px solid #6e92b4;
        display: inline;
        padding: 0 4px; }
        #sidebar .footer ul li:first-child {
          border: 0;
          padding-left: 0; }
    #sidebar .footer p {
      font-size: 11px; }
    #sidebar .footer .logo {
      margin-bottom: 11px; }
  #sidebar .form-group .sub-title {
    color: #6e92b4;
    display: block;
    font-size: 14px;
    line-height: 20px;
    padding-bottom: 6px; }
  #sidebar .btn {
    display: block;
    width: 100%; }
  #sidebar .check-list {
    color: #fff; }
  #sidebar .flags-section {
    max-height: 425px;
    overflow-y: auto;
    width: 245px !important; }

.grey-out .input-control.input-large input {
  cursor: default;
  filter: alpha(opacity=40);
  opacity: 0.4; }

.grey-out label {
  cursor: default; }

.form-group {
  display: flex;
  margin: 0;
  padding: 0 0 0px; }
  .form-group .input-control:nth-child(2) {
    margin-left: 40px; }

.form-control {
  border-radius: 0;
  box-shadow: none;
  outline: none;
  transition: border-color 0.4s ease-in-out 0s; }
  .form-control:focus {
    box-shadow: none; }
  .form-control::-ms-reveal, .form-control::-ms-clear {
    display: none; }

.input-control {
  flex-grow: 1;
  padding: 16px 0;
  position: relative; }
  .input-control.input-upper label, .input-control.input-upper input {
    text-transform: uppercase; }
  .input-control.input-large {
    padding-bottom: 20px;
    padding-top: 20px;
    width: 230px; }
    .input-control.input-large .form-control {
      height: 40px; }
      .input-control.input-large .form-control::-webkit-input-placeholder {
        font-size: 24px; }
      .input-control.input-large .form-control::-moz-placeholder {
        font-size: 24px; }
      .input-control.input-large .form-control:-ms-input-placeholder {
        font-size: 24px; }
      .input-control.input-large .form-control:-moz-placeholder {
        font-size: 24px; }
    .input-control.input-large label {
      font-size: 14px;
      line-height: 20px; }
  .input-control.input-small .form-control {
    font-size: 14px;
    width: 90px; }
  .input-control.invalid-state .form-control, .input-control.focus-state .form-control {
    border-bottom: 2px solid #1a8bf5 !important;
    font-weight: normal;
    padding-bottom: 7px; }
  .input-control.invalid-state.input-large .form-control, .input-control.focus-state.input-large .form-control {
    font-size: 30px; }
  .input-control.focus-state label {
    opacity: 1;
    top: 0; }
  .input-control.invalid-state .form-control {
    border-bottom: 2px solid #ff5252 !important; }
  .input-control.invalid-state .error {
    bottom: 0;
    opacity: 1; }
  .input-control.invalid-state .symbols {
    color: #ff5252; }
  .input-control label {
    color: #6e92b4;
    font-size: 12px;
    left: 0;
    line-height: 16px;
    position: absolute;
    top: 5px;
    transition: opacity, top 0.4s ease; }
  .input-control .form-control {
    background: none;
    border: 0;
    border-bottom: 1px solid #5d768e;
    color: #fff;
    font-size: 18px;
    font-weight: 300;
    height: 30px;
    line-height: 29px;
    padding-left: 0;
    padding-right: 0; }
    .input-control .form-control::-webkit-input-placeholder {
      color: #94a4b2; }
    .input-control .form-control::-moz-placeholder {
      color: #94a4b2; }
    .input-control .form-control:-ms-input-placeholder {
      color: #94a4b2; }
    .input-control .form-control:-moz-placeholder {
      color: #94a4b2; }
  .input-control .form-control-trade-mode {
    width: 200px; }
  .input-control textarea.form-control {
    height: 126px;
    line-height: 28px;
    resize: none; }
  .input-control .error {
    bottom: 15px;
    color: #ff5252;
    font-size: 13px;
    font-style: normal;
    left: 0;
    line-height: 16px;
    opacity: 0;
    position: absolute;
    transition: opacity, bottom 0.4s ease; }
  .input-control .error-search-form {
    bottom: -2px;
    color: #ff5252;
    font-size: 13px;
    font-style: normal;
    left: 0;
    line-height: 16px;
    position: absolute;
    transition: opacity, bottom 0.4s ease; }
  .input-control .symbols {
    color: #757575;
    display: inline-block;
    float: right;
    font-size: 12px;
    font-style: normal;
    line-height: 22px;
    padding: 5px 0; }
  .input-control.add-type .form-control {
    border-bottom-width: 2px;
    border-color: #e0e0e0;
    color: #000;
    font-size: 16px;
    font-weight: normal !important;
    height: 32px;
    padding-bottom: 8px; }
  .input-control.add-type textarea.form-control {
    color: #000;
    height: 126px;
    padding-bottom: 6px; }
  .input-control .disabled {
    cursor: default;
    filter: alpha(opacity=40);
    opacity: 0.4; }

#content-main .form-control {
  border-bottom-width: 2px;
  border-color: #e0e0e0;
  color: #163857;
  font-size: 16px;
  font-weight: 600;
  height: 24px; }
  #content-main .form-control::-webkit-input-placeholder {
    color: #163857;
    font-weight: normal; }
  #content-main .form-control::-moz-placeholder {
    color: #163857;
    font-weight: normal; }
  #content-main .form-control:-ms-input-placeholder {
    color: #163857;
    font-weight: normal; }
  #content-main .form-control:-moz-placeholder {
    color: #163857;
    font-weight: normal; }

.user-select {
  height: 40px !important;
  max-width: 220px; }
  .user-select option {
    background: #163857; }

.btn-group .icon {
  display: inline-block;
  margin-right: 7px; }

.btn-group.open .dropdown-menu, .btn-group.open .custom-dropdown-menu {
  opacity: 1;
  visibility: visible; }

.btn-group .btn-link {
  border: 0;
  color: #163857;
  display: inline-block;
  font-size: 13px;
  font-weight: 600;
  line-height: 20px;
  padding: 0;
  position: relative;
  vertical-align: top;
  z-index: 5 !important; }
  .btn-group .btn-link:hover {
    color: #3b72a4;
    text-decoration: none; }

.btn-group .icon {
  margin-top: -2px; }

.btn-group .dropdown-menu, .btn-group .custom-dropdown-menu {
  border: 0;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.25);
  display: block;
  left: -27px;
  min-width: 168px;
  opacity: 0;
  padding: 38px 0 5px;
  text-align: right;
  top: -13px;
  transition: all 0.3s ease;
  visibility: hidden;
  z-index: 4 !important; }
  .btn-group .dropdown-menu li a, .btn-group .custom-dropdown-menu li a {
    font-size: 13px;
    line-height: 20px;
    padding: 10px 40px 10px 20px; }

.btn-group .custom-dropdown-menu {
  left: -20px;
  text-align: left; }

.btn-group .drop-down {
  bottom: -100px;
  padding-top: 45px;
  text-align: left;
  top: -10px;
  z-index: 4 !important; }

.btn-group .drop-down-review-mode {
  bottom: -140px; }

.btn-group .drop-up {
  bottom: -10px;
  left: -18px;
  padding: 0 0 40px;
  text-align: left;
  top: -90px;
  z-index: 4 !important; }

.btn-group .da-button {
  text-decoration: none !important; }

.btn-group .da-button:hover {
  color: #333333;
  cursor: default; }

.contra-party-dd .custom-dropdown {
  width: 323px !important; }

.custom-dropdown {
  display: inline-block;
  font-weight: bold;
  margin-left: 7px;
  margin-right: 6px;
  margin-bottom: 10px;
  width: 342px; }
  .custom-dropdown .dropdown-label {
    bottom: 1px;
    display: inline-block;
    font-size: 13px;
    margin-right: 3px;
    position: relative; }

.reporting-party-type-dd .btn-group .custom-dropdown-menu {
  z-index: 8 !important; }

.reporting-party-type-dd .btn-group .btn-link {
  z-index: 9 !important; }

.reporting-party-type-dd-under .btn-group .custom-dropdown-menu {
  z-index: 6 !important; }

.reporting-party-type-dd-under .btn-group .btn-link {
  z-index: 7 !important; }

.data-field-dd {
  margin-left: 38px; }

.bottom-panel {
  background: #f6fcfe;
  bottom: 0;
  box-shadow: 0px -1px 6px rgba(0, 0, 0, 0.4);
  height: 64px;
  left: 0;
  padding: 12px 0;
  position: absolute;
  right: 0;
  z-index: 8 !important; }
  .bottom-panel.approved {
    background: #edffe2; }
  .bottom-panel.default-panel {
    background: #fff; }
  .bottom-panel > .container-fluid {
    padding-left: 40px; }
  .bottom-panel .text-selected {
    color: #163857;
    font-weight: 600;
    line-height: 38px; }

.pdf-button {
  clear: both;
  color: #3b3b3b;
  display: block;
  font-weight: normal;
  white-space: nowrap; }

.top-panel {
  background: #f6fcfe;
  box-shadow: 0px -1px 10px rgba(0, 0, 0, 0.4);
  height: 0;
  margin-left: -6px;
  overflow: hidden;
  position: relative;
  transition: height 0.5s;
  z-index: 4; }
  .top-panel .holder {
    padding: 10px 0 10px 0; }
  .top-panel dl {
    color: #163857;
    font-size: 12px;
    line-height: 15px;
    margin: 0;
    white-space: nowrap; }
    .top-panel dl dt {
      clear: both;
      float: left;
      font-weight: 400;
      line-height: 15px;
      padding-right: 12px;
      text-align: right;
      width: 200px; }
    .top-panel dl dd {
      font-weight: 600;
      height: 15px;
      line-height: 15px;
      overflow: hidden;
      text-overflow: ellipsis; }
  .top-panel div.row .security-details:first-child dl dt {
    width: 150px; }
  .top-panel div.row .security-details:last-child dl dt {
    width: 236px; }
  .top-panel div.row .obligor dl dt {
    float: none; }

.read-mode .export-button-holder {
  margin-top: -50px;
  margin-left: 40px; }

.read-mode .review-back-button {
  float: left;
  margin: -30px 0 20px 20px; }

.read-mode .additional-snapshot-info {
  float: right;
  font-size: 13px;
  margin-top: -30px;
  padding-right: 20px; }
  .read-mode .additional-snapshot-info .checkmark-success {
    color: #a1db31; }
  .read-mode .additional-snapshot-info .user-info {
    font-weight: bold; }

.read-mode .review-section {
  clear: both; }
  .read-mode .review-section .holder {
    background: #f7fbfc;
    padding: 12px 0 11px; }
  .read-mode .review-section .chart {
    float: left;
    width: 280px;
    padding-right: 10px; }
    @media (max-width: 1599px) {
      .read-mode .review-section .chart {
        padding-right: 10px;
        width: 280px; } }
  .read-mode .review-section .column {
    column-count: 2;
    column-gap: 30px;
    float: left;
    width: 67.8%; }
    @media (max-width: 1599px) {
      .read-mode .review-section .column {
        column-gap: 10px; } }
  .read-mode .review-section dl {
    color: #163857;
    font-size: 12px;
    line-height: 12px;
    margin: 0;
    white-space: nowrap; }
    .read-mode .review-section dl dt {
      clear: both;
      float: left;
      font-weight: 400;
      line-height: 12px;
      padding-right: 6px;
      text-align: right;
      width: 140px; }
    .read-mode .review-section dl dd {
      font-weight: 600;
      height: 20px;
      line-height: 12px;
      overflow: hidden;
      padding: 0 0 0 4px;
      text-overflow: ellipsis; }
  .read-mode .review-section .obligor dl dt {
    float: none; }
  .read-mode .review-section .obligor dl dd {
    padding: 4px 0 0 0; }
  .read-mode .review-section .obligor dl .more {
    cursor: default; }

.line.selected .opened {
  display: none; }

.line .circle {
  border-radius: 50%;
  display: inline-block;
  float: left;
  height: 10px;
  margin-right: 5px;
  margin-top: 5px;
  width: 10px; }
  .line .circle.coloured {
    background: dodgerblue; }

.new {
  width: 10px; }

.tolerance-mark {
  width: 27px;
  text-align: center; }
  .tolerance-mark span {
    color: #FF0000;
    font-size: 20px; }

.check-flag {
  margin: 15px 0; }
  .check-flag .check-label {
    color: white; }

.details-section {
  position: relative; }
  .details-section .chart {
    float: left;
    width: 45%; }
    @media (max-width: 1599px) {
      .details-section .chart {
        width: 50%; } }
  .details-section .column {
    float: left;
    padding: 0 40px 0 0;
    width: 55%; }
    @media (max-width: 1599px) {
      .details-section .column {
        width: 48%; } }
  .details-section .circle {
    border-radius: 50%;
    display: inline-block;
    height: 12px;
    line-height: 15px;
    margin-bottom: -2px;
    margin-right: 5px;
    width: 12px; }
    .details-section .circle.bid {
      background: #ff8227; }
    .details-section .circle.offer {
      background: #2bbd2b; }
  .details-section .underline {
    border: 0;
    color: #1a8bf5;
    margin-right: 25px; }
  .details-section .btn-link.active .underline,
  .details-section .btn-link:hover .underline {
    border-bottom: 1px solid #0864ba;
    color: #0864ba; }
  .details-section .btn-link:focus {
    text-decoration: none; }
  .details-section .bw-flag {
    color: #163857;
    display: inline-block;
    float: right;
    font-size: 13px;
    margin-top: 2px; }

.history-section {
  position: relative; }
  .history-section .chart {
    float: left;
    width: 45%; }
    @media (max-width: 1599px) {
      .history-section .chart {
        width: 50%; } }
  .history-section .column {
    float: left;
    padding: 0 40px 0 0;
    width: 55%; }
    @media (max-width: 1599px) {
      .history-section .column {
        width: 48%; } }
  .history-section .finra-chart {
    position: relative;
    top: -30px; }
  .history-section .recent-trades-note {
    font-size: 13px;
    margin-left: 8px;
    margin-top: 15px;
    top: 34px; }

.table-default {
  color: #2b3844;
  font-size: 12px; }
  .table-default table {
    border-collapse: collapse;
    width: 100%; }
  .table-default td,
  .table-default th {
    line-height: 26px;
    padding: 2px 10px; }
  .table-default th {
    color: #607d8b;
    font-size: 13px;
    font-weight: 600; }
  .table-default tbody tr:nth-child(odd) td {
    background: rgba(247, 251, 252, 0.7); }
  .table-default tbody tr:hover td {
    background: #b2deff; }
  .table-default .checked {
    color: #b91913; }
  .table-default .trade-type-flag {
    color: #0e63b3;
    cursor: default;
    font-size: 11px;
    font-weight: bold;
    margin-top: 0;
    max-width: 27px;
    width: 27px; }
  .table-default .tick {
    font-size: 0;
    line-height: 0; }
  .table-default .checked .tick {
    font-size: 12px;
    line-height: 26px; }
  .table-default .buy-side {
    color: #ff8227; }
  .table-default .sell-side {
    color: #2bbd2b; }
  .table-default .correction-mark {
    width: 27px;
    color: #0880b9 !important; }

.table-total {
  color: #2b3844;
  font-size: 12px;
  padding-left: 22px;
  width: 388px; }
  .table-total table {
    border-collapse: collapse;
    width: 100%; }
  .table-total td,
  .table-total th {
    line-height: 20px;
    min-width: 84px;
    padding: 5px 7px; }
    .table-total td:first-child,
    .table-total th:first-child {
      background: none;
      color: #b0bec5;
      padding-right: 22px;
      text-align: right;
      width: 128px; }
  .table-total th {
    color: #607d8b;
    font-size: 13px;
    font-weight: 600;
    padding-bottom: 9px; }
  .table-total tbody tr:nth-child(odd) td {
    background: rgba(247, 251, 252, 0.7); }
    .table-total tbody tr:nth-child(odd) td:first-child {
      background: none; }
  .table-total tbody tr:hover td {
    background: #b2deff; }
    .table-total tbody tr:hover td:first-child {
      background: none; }
  .table-total tfoot tr td {
    color: #b0bec5; }

.read-mode .table-total {
  font-size: 16px;
  padding-left: 0 !important;
  width: 250px !important; }
  .read-mode .table-total td:first-child,
  .read-mode .table-total th:first-child {
    color: #757575;
    padding-right: 0;
    text-align: left;
    width: auto; }
  .read-mode .table-total th {
    color: #757575;
    font-size: 14px;
    font-weight: normal; }
  .read-mode .table-total tbody td:first-child {
    background: rgba(247, 251, 252, 0.7) !important;
    color: #2b3844; }
  .read-mode .table-total tbody tr:first-child > td {
    background: rgba(247, 251, 252, 0.7) !important;
    color: #607d8b; }
  .read-mode .table-total tbody tr:nth-child(2) > td {
    background: none !important;
    font-weight: 600; }
  .read-mode .table-total tbody tr:nth-child(2):hover td {
    background: #b2deff !important; }

.table-fixed-header {
  color: #2b3844;
  font-size: 12px;
  padding-top: 30px;
  position: relative; }
  .table-fixed-header .table-holder {
    height: 300px;
    overflow-y: auto; }
  .table-fixed-header table {
    border-collapse: collapse;
    width: 100%; }
  .table-fixed-header td,
  .table-fixed-header th {
    line-height: 16px;
    padding: 2px 7px; }
  .table-fixed-header th {
    border: none;
    color: transparent;
    height: 0;
    line-height: 0;
    padding-bottom: 0;
    padding-top: 0;
    white-space: nowrap; }
  .table-fixed-header th > div {
    color: #607d8b;
    font-size: 13px;
    font-weight: 600;
    line-height: 26px;
    margin-left: -7px;
    padding: 2px 7px;
    position: absolute;
    top: 0; }
  .table-fixed-header tbody tr {
    outline: none; }
    .table-fixed-header tbody tr:nth-child(odd) td {
      background-color: rgba(247, 251, 252, 0.7); }
    .table-fixed-header tbody tr.active td {
      background: #b2deff; }
    .table-fixed-header tbody tr:hover td {
      background: #dcdcdc; }
  .table-fixed-header .text-orange {
    color: #ff8227; }
  .table-fixed-header .text-grey {
    color: #8f8b8b; }
  .table-fixed-header .text-green {
    color: #2bbd2b; }
  .table-fixed-header .text-blue {
    color: #3eb3e8; }

.comparables-section {
  position: relative; }
  .comparables-section .chart {
    float: left;
    position: relative;
    width: 560px; }
    .comparables-section .chart .controls {
      position: absolute;
      top: 35px; }
    .comparables-section .chart .chart-type {
      left: 40px; }
    .comparables-section .chart .data-type {
      left: 270px; }
    .comparables-section .chart .circle {
      border-radius: 50%;
      display: inline-block;
      height: 12px;
      line-height: 15px;
      margin-bottom: -2px;
      margin-right: 5px;
      width: 12px; }
      .comparables-section .chart .circle.best-bid {
        background: #ff8227; }
      .comparables-section .chart .circle.best-offering {
        background: #2bbd2b; }
      .comparables-section .chart .circle.last {
        background: #3eb3e8; }
    .comparables-section .chart .btn {
      border: 0;
      font-size: 12px;
      line-height: 17px;
      padding-left: 15px;
      padding-right: 0px; }
    .comparables-section .chart .underline {
      border: 0;
      color: #1a8bf5; }
    .comparables-section .chart .btn-link.active .underline,
    .comparables-section .chart .btn-link:hover .underline {
      border-bottom: 1px solid #0864ba;
      color: #0864ba; }
    .comparables-section .chart .btn-link:focus {
      text-decoration: none; }
    .comparables-section .chart .dropdown {
      padding-left: 20px; }
    .comparables-section .chart .dropdown-menu, .comparables-section .chart .btn-group .custom-dropdown-menu, .btn-group .comparables-section .chart .custom-dropdown-menu {
      margin-left: 36px;
      padding-top: 42px; }
  .comparables-section .column {
    float: right;
    width: 53%; }
    @media (max-width: 1599px) {
      .comparables-section .column {
        width: 47%; } }
  .comparables-section .holder {
    padding-bottom: 25px; }
  .comparables-section .custom-checkbox {
    margin-bottom: 0; }
  .comparables-section .bond-total {
    padding-top: 30px;
    padding-left: 150px;
    position: relative; }
  .comparables-section h2 {
    color: #90a4ae; }
  .comparables-section .text-label {
    display: inline-flex; }

.filter-section {
  margin-bottom: 8px;
  margin-top: -20px; }
  .filter-section .header {
    padding: 0 0 30px 30px; }
  .filter-section .title {
    color: #0e63b3;
    font-size: 22px;
    font-weight: 600;
    line-height: 30px;
    padding-left: 20px; }

.filter-list {
  margin-right: 0px; }
  .filter-list .text-label {
    color: #b0bec5;
    flex-grow: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    min-width: 115px;
    padding: 0 9px 0 0;
    text-align: right; }
  .filter-list .line {
    display: flex;
    flex-grow: 1; }
  .filter-list li {
    display: flex; }
  .filter-list .input-control {
    padding-top: 0; }
  .filter-list .col {
    flex-grow: 1;
    padding-left: 20px; }

#content-main > .heading {
  color: #163857;
  padding: 17px 20px 12px; }

#content-main > .heading + .content-main-holder {
  height: calc(100vh - 193px);
  min-height: 506px;
  padding-top: 0; }
  @media (max-width: 1279px) {
    #content-main > .heading + .content-main-holder {
      height: calc(100vh - 211px); } }

.trade-table {
  padding: 20px 0; }
  .trade-table .heading {
    color: #163857;
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    padding-bottom: 10px; }
  .trade-table .content {
    border: 1px solid #a8c7e4; }
  .trade-table .table-default {
    margin-bottom: 4px; }
    .trade-table .table-default th:first-child, .trade-table .table-default td:first-child {
      padding-left: 10px; }
  .trade-table .line {
    border-top: 1px solid #a8c7e4; }
    .trade-table .line:first-child {
      border: 0; }
    .trade-table .line.selected .holder, .trade-table .line:hover .holder {
      box-shadow: 0px 3px 8px 2px rgba(0, 0, 0, 0.2);
      cursor: pointer;
      margin: -1px -1px 0;
      padding: 16px 11px 15px; }
      .trade-table .line.selected .holder .title, .trade-table .line:hover .holder .title {
        color: #1a8bf5; }
    .trade-table .line.selected .holder {
      cursor: inherit;
      padding: 16px 17px 11px 33px; }
    .trade-table .line.selected .title {
      display: inline-block;
      font-size: 28px;
      line-height: 30px; }
    .trade-table .line.selected .trades-count {
      display: inline-block;
      font-size: 16px;
      text-transform: uppercase; }
    .trade-table .line.selected .thead {
      padding-bottom: 18px; }
    .trade-table .line.selected .tbody {
      display: block; }
    .trade-table .line .holder {
      padding: 15px 10px; }
    .trade-table .line .info {
      color: #9a9a9a;
      font-weight: 600;
      line-height: 18px; }
    .trade-table .line .title {
      color: #163857;
      display: block;
      font-weight: 600;
      padding-right: 16px; }
    .trade-table .line p {
      font-size: 14px;
      margin-bottom: 0; }
    .trade-table .line .time {
      color: #607d8b;
      font-size: 12px;
      padding-top: 3px;
      text-transform: uppercase; }
    .trade-table .line .divider {
      padding: 0 8px; }
    .trade-table .line .thead {
      outline: none; }
    .trade-table .line .tbody {
      margin: 0 -16px 0 -32px; }

.live-indicator {
  padding: 0 !important;
  width: 4px; }

.line-green {
  background-color: #3fc33f !important; }

.line-red {
  background-color: #ff5252 !important; }

.order-date {
  margin-left: 2px; }

.page-alert {
  width: 500px;
  font-weight: bold;
  font-size: 15px;
  text-align: center;
  margin: 0 auto; }

.rzslider {
  font-size: 13px; }
  .rzslider:hover .rz-pointer,
  .rzslider:hover .rz-selection {
    background: #1a8bf5; }
  .rzslider .rz-bar-wrapper {
    padding-top: 6px; }
  .rzslider .rz-bubble {
    bottom: 16px; }
  .rzslider .rz-selection {
    background: #82add9;
    height: 2px; }
  .rzslider .rz-pointer {
    background: #82add9;
    height: 12px;
    margin-top: -15px;
    top: 0;
    width: 12px; }
    .rzslider .rz-pointer:after {
      width: 0;
      height: 0; }

.vertical-modal .modal-lg {
  width: 600px !important; }

.horizontal-modal .modal-lg {
  width: 1000px !important; }

.da-modal p {
  margin-bottom: 5px !important;
  margin-top: 5px !important; }

.da-modal .modal-body {
  padding: 0; }

.da-modal ul {
  margin-bottom: 7px !important; }

.modal-dialog h2 {
  color: #90a4ae; }

.modal-dialog .modal-header {
  border: 0;
  padding-bottom: 0;
  padding-top: 23px; }
  .modal-dialog .modal-header h1 {
    margin-bottom: 16px; }

.modal-dialog .modal-content {
  border: 0;
  border-radius: 3px;
  box-shadow: 0 15px 100px rgba(0, 0, 0, 0.5) !important;
  padding-bottom: 0;
  padding-top: 0; }

.modal-dialog .modal-body {
  padding-bottom: 0;
  padding-top: 0; }
  .modal-dialog .modal-body .copyright {
    font-size: 13px;
    padding-bottom: 10px;
    text-align: justify; }
    .modal-dialog .modal-body .copyright ol {
      counter-reset: item;
      margin-left: 0;
      padding-left: 0; }
    .modal-dialog .modal-body .copyright ol li {
      display: block;
      margin-bottom: 5px;
      margin-left: 30px; }
    .modal-dialog .modal-body .copyright ol li::before {
      content: counter(item) "";
      counter-increment: item;
      display: inline-block;
      margin-left: -20px;
      width: 20px; }
    .modal-dialog .modal-body .copyright .about-us {
      float: left;
      width: 60px; }
    .modal-dialog .modal-body .copyright .disabled {
      background-color: #eeeeee;
      border-radius: 10px;
      border-width: 2px;
      border-color: lightgrey;
      margin: 15px 0;
      padding: 0 10px; }
  .modal-dialog .modal-body .custom-error {
    color: #ff5252;
    font-size: 13px;
    font-style: italic; }
  .modal-dialog .modal-body.direct-access textarea {
    height: 150px;
    margin-top: 20px;
    width: 540px; }

.modal-dialog .modal-footer {
  border: 0; }
  .modal-dialog .modal-footer .approve-btn {
    height: 40px;
    margin-left: 20px;
    width: 90px; }

.modal-dialog .btn-link {
  color: #546e7a;
  min-width: 84px; }
  .modal-dialog .btn-link:hover {
    opacity: 0.7; }

.modal-dialog .btn-primary {
  box-shadow: none; }

.modal-dialog .close {
  color: #284764;
  font-size: 38px;
  font-weight: 300;
  margin-right: -6px;
  margin-top: -7px;
  opacity: 1; }
  .modal-dialog .close:hover {
    opacity: 0.7; }

.modal-dialog p {
  margin-bottom: 29px;
  margin-top: 18px; }

.modal-dialog .input-control {
  padding-bottom: 0;
  padding-top: 8px; }

.modal-dialog ul {
  margin-bottom: 17px; }

.paging {
  font-weight: 600;
  line-height: 18px;
  padding-top: 12px; }
  .paging li {
    display: inline-block; }
    .paging li:hover a, .paging li.active a {
      color: #1a8bf5; }
    .paging li a {
      color: #b8b8b8;
      font-size: 16px;
      font-weight: bold;
      padding: 0 2px;
      position: relative;
      top: 1px; }
    .paging li a .pagination-arrow {
      color: #1a8bf5;
      font-size: 22px; }

.sort-list {
  margin: 6px 0 0; }
  .sort-list li {
    display: inline-block;
    font-weight: 600;
    padding-right: 16px;
    vertical-align: top; }
    .sort-list li:hover a, .sort-list li.active a {
      border-color: #fff;
      color: #163857; }
  .sort-list a {
    border-bottom: 1px solid #83afd8;
    color: #0e63b3;
    display: inline-block;
    vertical-align: top; }

rect {
  shape-rendering: crispEdges; }

.axis path,
.axis line {
  fill: none;
  stroke: rgba(0, 0, 0, 0.1);
  shape-rendering: crispEdges; }

.axis text {
  fill: #97a7a8;
  font-size: 10px;
  stroke: rgba(0, 0, 0, 0.1); }

.axis-line {
  fill: none;
  shape-rendering: crispEdges;
  stroke-width: 2px; }

.dot {
  fill-opacity: .5; }
  .dot:hover, .dot:focus {
    outline: none; }
  .dot.active {
    fill-opacity: 1; }

/* Creates a small triangle extender for the tooltip */
.d3-tip {
  background: rgba(0, 0, 0, 0.8);
  border-radius: 2px;
  color: #fff;
  font-weight: bold;
  font-size: 12px;
  line-height: 1;
  padding: 12px;
  z-index: 100;
  /* Style northward tooltips differently */ }
  .d3-tip:after {
    box-sizing: border-box;
    color: rgba(0, 0, 0, 0.8);
    content: "\25BC";
    display: inline;
    font-size: 10px;
    line-height: 1;
    position: absolute;
    text-align: center;
    width: 100%; }
  .d3-tip.n:after {
    margin: -1px 0 0 0;
    left: 0;
    top: 100%; }

.legend text {
  font-size: 14px;
  fill: #97a7a8; }

.tick text {
  font-size: 10px; }

.chart-legend text {
  font-size: 12px;
  fill: #878787;
  stroke: rgba(0, 0, 0, 0.1); }

.company-form-group option {
  overflow: hidden;
  text-overflow: ellipsis;
  width: 550px; }

.user-management-page {
  background-color: #fff;
  font-size: 13px;
  height: 100%;
  overflow-y: auto; }
  .user-management-page .user-controls {
    padding-top: 20px; }
  .user-management-page .btn-primary {
    box-shadow: none; }
  .user-management-page .btn {
    line-height: 1.5; }
  .user-management-page .label-users {
    font-size: 30px; }
  .user-management-page .btn-create-user {
    float: right; }
  .user-management-page .btn-bu {
    float: right;
    margin-left: 10px; }
  .user-management-page .table-user {
    font-size: 12px; }
    .user-management-page .table-user th,
    .user-management-page .table-user td {
      padding: 3px !important;
      vertical-align: middle !important; }
    .user-management-page .table-user .email {
      max-width: 169px;
      width: 169px;
      word-wrap: break-word; }
    .user-management-page .table-user .name {
      max-width: 129px;
      width: 129px;
      word-wrap: break-word; }
    .user-management-page .table-user .status {
      max-width: 70px;
      text-align: center;
      width: 70px; }
    .user-management-page .table-user .groups {
      max-width: 142px;
      width: 142px; }
    .user-management-page .table-user .date {
      max-width: 104px;
      text-align: center;
      width: 104px; }
    .user-management-page .table-user .btns {
      max-width: 70px;
      width: 70px; }

.edit-user-form,
.delete-user-form {
  font-size: 14px; }
  .edit-user-form p,
  .delete-user-form p {
    margin: 0 0 10px; }
  .edit-user-form .modal-header,
  .delete-user-form .modal-header {
    border-bottom: 1px solid #e5e5e5;
    font-size: 18px;
    padding: 15px; }
  .edit-user-form .modal-body,
  .delete-user-form .modal-body {
    padding: 15px; }
    .edit-user-form .modal-body label,
    .delete-user-form .modal-body label {
      font-weight: bold; }
    .edit-user-form .modal-body .form-group,
    .delete-user-form .modal-body .form-group {
      display: block;
      margin-bottom: 15px; }
      .edit-user-form .modal-body .form-group:last-of-type,
      .delete-user-form .modal-body .form-group:last-of-type {
        margin-bottom: 0; }
    .edit-user-form .modal-body .form-group.password input,
    .delete-user-form .modal-body .form-group.password input {
      width: 300px; }
    .edit-user-form .modal-body .form-group.password label,
    .delete-user-form .modal-body .form-group.password label {
      display: block; }
    .edit-user-form .modal-body .form-group.password div,
    .edit-user-form .modal-body .form-group.password ul,
    .edit-user-form .modal-body .form-group.password input:first-of-type,
    .delete-user-form .modal-body .form-group.password div,
    .delete-user-form .modal-body .form-group.password ul,
    .delete-user-form .modal-body .form-group.password input:first-of-type {
      display: inline; }
    .edit-user-form .modal-body .form-group.password .strength,
    .delete-user-form .modal-body .form-group.password .strength {
      padding-left: 10px; }
    .edit-user-form .modal-body .form-group.password .strength-bar,
    .delete-user-form .modal-body .form-group.password .strength-bar {
      padding-left: 20px; }
    .edit-user-form .modal-body .info-message,
    .delete-user-form .modal-body .info-message {
      font-size: 12px !important;
      font-style: italic; }
  .edit-user-form .modal-footer,
  .delete-user-form .modal-footer {
    padding: 15px;
    border-top: 1px solid #e5e5e5; }

.trader-comments {
  line-height: 20px;
  width: 50%; }
  .trader-comments .comment {
    border: 1px solid #a8c7e4;
    margin-bottom: 5px;
    padding: 7px; }
    .trader-comments .comment .meta {
      font-size: 13px; }
      .trader-comments .comment .meta .badge {
        background-color: #1f92f2;
        font-size: 10px; }
      .trader-comments .comment .meta .circle:before {
        color: #1E90FF;
        content: ' \25CF';
        font-size: 20px; }
      .trader-comments .comment .meta .date {
        color: #607d8b;
        font-size: 10px;
        padding-left: 5px;
        padding-top: 5px; }
      .trader-comments .comment .meta .email {
        max-width: 320px;
        overflow: hidden;
        padding-top: 5px;
        text-overflow: ellipsis;
        white-space: nowrap; }
      .trader-comments .comment .meta .manager {
        color: #fff;
        margin-right: 20px;
        padding-top: 2px; }
    .trader-comments .comment .message {
      clear: both;
      color: #607d8b;
      font-size: 13px;
      padding: 10px 0;
      word-wrap: break-word; }
    .trader-comments .comment hr {
      margin: 35px 0 0 0; }

.best-execution-section {
  position: relative; }
  .best-execution-section .data {
    float: left;
    padding-left: 45px; }
    .best-execution-section .data .company-data {
      float: left;
      margin-top: 13px;
      margin-left: 15px; }
      .best-execution-section .data .company-data td:first-child {
        width: 95px; }
      .best-execution-section .data .company-data td:last-child {
        width: 140x; }
      .best-execution-section .data .company-data .table-default {
        width: 100%; }
    .best-execution-section .data .last-30-days {
      float: left;
      margin-left: 15px; }
      .best-execution-section .data .last-30-days .table-default {
        margin-bottom: 15px;
        margin-top: -15px; }
      .best-execution-section .data .last-30-days td {
        width: 70px; }
      .best-execution-section .data .last-30-days td:first-child {
        width: 18px; }
    .best-execution-section .data .last-day {
      float: left; }
      .best-execution-section .data .last-day .table-default {
        margin-bottom: 15px;
        margin-top: -15px; }
      .best-execution-section .data .last-day td {
        width: 70px; }
      .best-execution-section .data .last-day td:first-child {
        width: 18px; }
    .best-execution-section .data p {
      color: #0e63b3;
      font-size: 13px;
      font-weight: bold;
      text-align: left; }
    .best-execution-section .data span {
      font-weight: bold; }
    .best-execution-section .data .table-default th, .best-execution-section .data .table-default td {
      line-height: 19px; }
    .best-execution-section .data .table-default th {
      padding: 10px 0; }
  .best-execution-section .tolerance-checks {
    float: left;
    font-size: 16px;
    width: 37%; }
    .best-execution-section .tolerance-checks .reviews .flag-list {
      padding-top: 0; }
    .best-execution-section .tolerance-checks .reviews .item {
      display: block; }
      .best-execution-section .tolerance-checks .reviews .item .check-mark {
        background-color: #fff;
        float: left;
        font-weight: 800;
        text-align: center;
        width: 30px; }
        .best-execution-section .tolerance-checks .reviews .item .check-mark.flag {
          font-weight: 100;
          padding-top: 4px; }
        .best-execution-section .tolerance-checks .reviews .item .check-mark.success {
          color: #a1db31; }
        .best-execution-section .tolerance-checks .reviews .item .check-mark.error {
          color: #FF0000; }
        .best-execution-section .tolerance-checks .reviews .item .check-mark.no-data {
          color: #faea00; }
        .best-execution-section .tolerance-checks .reviews .item .check-mark .count {
          font-size: 20px;
          margin-top: -2px; }
      .best-execution-section .tolerance-checks .reviews .item .rule {
        display: block;
        margin-left: 40px; }
    .best-execution-section .tolerance-checks .reviews p.text-label {
      color: #0e63b3;
      font-weight: bold;
      margin-bottom: 10px;
      text-align: left; }
    .best-execution-section .tolerance-checks .reviews ul li {
      color: #163857;
      line-height: 28px;
      margin-top: -5px;
      padding-left: 0; }
      .best-execution-section .tolerance-checks .reviews ul li div {
        margin-bottom: 2px; }

.watermark {
  color: #e2e2e2;
  line-height: 0;
  opacity: 0.5;
  transform: rotate(-30deg);
  position: relative;
  z-index: 0;
  -moz-transform: rotate(-30deg);
  -o-transform: rotate(-30deg);
  -webkit-transform: rotate(-30deg); }
  .watermark p {
    margin: 0; }

checkbox-toggle-group span:focus {
  border: 0;
  outline: none; }

checkbox-toggle-group .ctg-title {
  color: white;
  cursor: pointer; }

checkbox-toggle-group .ctg-title-container {
  color: white;
  margin-bottom: 15px; }

checkbox-toggle-group .ctg-toggle-icon {
  color: #1a8bf5;
  cursor: pointer;
  margin-right: 4px; }

checkbox-toggle-group .ctg-total-checked {
  color: white; }

checkbox-toggle-group .ctg-total-checked:hover {
  color: #1a8bf5; }

checkbox-toggle-group .ctg-checkbox-container {
  margin-left: 25px; }

checkbox-toggle-group .form-group {
  margin: 0 0 15px 0; }

.custom-tooltip .tooltip-inner {
  max-width: none;
  white-space: nowrap; }

.custom-tooltip-big-content .tooltip-inner {
  width: 400px;
  max-width: 400px; }

.tooltip-sign {
  cursor: pointer; }

.tooltip-sign:focus {
  outline: none; }

.tooltip-sign-dropdown {
  color: rgba(24, 24, 24, 0.6); }

.tooltip-sign-dropdown:hover {
  color: #000000; }

.tooltip-sign-search {
  color: rgba(110, 146, 180, 0.7);
  font-size: 14px; }

.tooltip-sign-search:hover {
  color: #94a4b2; }

.tooltip-sign-search-input {
  position: absolute;
  right: -5px;
  top: 34px; }

.tooltip-sign-trade-section {
  color: rgba(14, 99, 179, 0.7);
  font-size: 18px;
  vertical-align: middle; }

.tooltip-sign-sub-section {
  color: rgba(14, 99, 179, 0.7);
  font-size: 14px;
  vertical-align: middle; }

.tooltip-sign-sub-section:hover,
.tooltip-sign-trade-section:hover {
  color: #0e63b3; }

.tooltip-sign-stats-section {
  color: rgba(96, 125, 139, 0.71); }

.tooltip-sign-stats-section:hover {
  color: #70919f; }

.tooltip-sign-review {
  margin-left: 6px;
  display: inline-flex;
  align-items: center; }

i {
  font-style: normal;
  font-family: 'FontAwesome', sans-serif; }

html,
body {
  height: 100%;
  margin: 0;
  min-width: 1280px;
  padding: 0;
  position: relative; }

body {
  min-height: 700px;
  overflow-y: hidden; }

button,
a {
  outline: none !important; }
  button:focus, button:active, button:hover,
  a:focus,
  a:active,
  a:hover {
    outline: none !important;
    text-decoration: none; }

label {
  font-weight: normal; }

h1 {
  color: #0e63b3;
  font-size: 28px;
  font-weight: 600;
  line-height: 30px;
  margin: 0 25px 18px 0; }

h2 {
  color: #0e63b3;
  font-size: 22px;
  font-weight: 600;
  line-height: 24px;
  margin: 0 0 18px; }

.item-block {
  display: block; }

/* main wrapper */
.main-wrapper {
  height: 100%;
  min-width: 1400px; }
  .main-wrapper.main-page {
    background: url(../images/bg-main.jpg) no-repeat;
    background-size: cover; }
    .main-wrapper.main-page .blank-search-page {
      background: white;
      height: 100vh; }
    .main-wrapper.main-page .search-page,
    .main-wrapper.main-page .review-page,
    .main-wrapper.main-page .read-mode {
      background: white; }
    .main-wrapper.main-page #content {
      padding: 95px 20px 95px 20px; }
      .main-wrapper.main-page #content h1 {
        font-size: 48px;
        font-weight: 300;
        line-height: 58px; }

.main-container {
  height: 100%;
  position: relative; }

/* with sidebar */
.with-sidebar #sidebar {
  display: block; }

.with-sidebar .main-container {
  margin-left: 265px; }

/* on scroll */
.on-scroll #content-main .content-main-holder {
  height: calc(100vh - 222px);
  min-height: 494px; }
  @media (max-width: 1279px) {
    .on-scroll #content-main .content-main-holder {
      height: calc(100vh - 221px); } }

.on-scroll #content-main.review-page .content-main-holder {
  height: calc(100vh - 129px); }

.on-scroll .top-panel {
  height: 105px; }

/* read mode */
.read-mode #content-main .content-main-holder {
  height: calc(100vh - 173px);
  min-height: 528px;
  padding-right: 0; }
  @media (max-width: 1279px) {
    .read-mode #content-main .content-main-holder {
      height: calc(100vh - 185px); } }

.read-mode #content-main {
  padding-left: 0; }

.read-mode .review-mode-container {
  height: 100%;
  margin-left: 0px;
  position: relative; }

/* content main */
#content-main {
  padding: 52px 0 62px 5px; }
  #content-main .content-main-holder {
    height: calc(100vh - 116px);
    margin-bottom: -30px;
    min-height: 585px;
    overflow: auto;
    padding: 48px 20px 0 0;
    transition: height 0.5s; }
    @media (max-width: 1279px) {
      #content-main .content-main-holder {
        height: calc(100vh - 131px); } }

.read-only-holder {
  background-color: #fff;
  height: calc(100vh - 118px) !important;
  min-height: 585px !important; }
  .read-only-holder .best-execution-section {
    margin-top: 20px; }

.content-section {
  margin-bottom: 25px; }

.img-placeholder {
  display: block;
  width: 100%; }

/* flag list */
.flag-list {
  color: #163857;
  line-height: 26px;
  padding-top: 8px; }
  .flag-list.bullets li {
    line-height: 28px;
    margin-bottom: 2px;
    padding-left: 30px;
    position: relative; }
    .flag-list.bullets li:before {
      color: #a1db31;
      content: "\e013";
      display: none;
      font-family: 'Glyphicons Halflings', serif;
      font-size: 16px;
      font-weight: 100;
      left: 0;
      line-height: 0.8;
      position: absolute;
      top: 6px; }
    .flag-list.bullets li.selected:before {
      display: block; }
  .flag-list li {
    margin-bottom: 6px; }

.modal .flag-list {
  font-size: 14px; }

/* wrap line */
.wrap-line {
  line-height: 28px;
  padding-top: 33px; }

.disable-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.review-mode-export {
  margin-right: 104px;
  margin-top: -51px;
  position: absolute;
  right: 0; }

.cursor-help {
  cursor: help; }
